import React from "react"
import queryString from "query-string"
import ContentSelectCategory from "../components/ContentSelectCategory"

const SelectCategory = ({ location }) => {
  const queryParams =
    location && location.search ? queryString.parse(location.search) : {}
  if (!queryParams.__cid) return <div>No such page</div>

  return (
    <ContentSelectCategory
      countryName={queryParams.name}
      countryID={queryParams.__id}
    />
  )
}

export default SelectCategory
