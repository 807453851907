import React, { useContext } from "react"
import styled from "styled-components"
import { AppContext } from "../../contexts/AppContext"
import StaticArtistImage from "../../images/pages/StaticArtistImage.png"
import StaticRadioImage from "../../images/pages/StaticStationImage.png"
import StaticArtistMobileImage from "../../images/pages/static_artist_mobile.png"
import StaticRadioMobileImage from "../../images/pages/static_radio_mobile.png"
import { Link } from "gatsby"
import { TAB, MEDIUM } from "../../constants/breakpoints"
import {
  convertToSlug,
  stationsPageURL,
  artistsPageURL,
  PROP_FONT_PRIMARY
} from "../../constants/props"

const ContentCategoryWrapper = styled.div`
  min-height: 300px;
`
const CountryDetails = styled.div`
  min-height: 60vh;
  width: 100%;
  padding: 15px;
  text-align: center;
  margin: 25px auto;
  border-radius: 30px;
`
const MainHeading = styled.h2`
  color: #dededf;
  text-align: center;
  margin-bottom: 2%;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: 24px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 32px;
  }
`
const Options = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: flex-start;
  @media (min-width: ${TAB.minWidth}px) {
    flex-direction: row;
    min-height: 200px;
    justify-content: center;
  }
`
const ChildDiv = styled.div`
  margin: 2%;
  & a:hover {
    text-decoration: none;
  }
  width: auto;
  display: none;
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 16%;
    display: block;
  }
`
const ChildDivMobile = styled.div`
  margin: 2%;
  & a:hover {
    text-decoration: none;
  }
  width: auto;
  display: block;
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 16%;
    display: none;
  }
`
const Image = styled.img`
  margin: 4% 0%;
  padding: 0% 20%;
  width: 100%;
  @media (min-width: ${TAB.minWidth}px) {
    padding: 0%;
  }
`
const Text = styled.p`
  font-family: ${PROP_FONT_PRIMARY};
  color: #efece9;
  margin: -14px;
  letter-spacing: 2px;
  font-size: 20px;
  @media (min-width: ${TAB.minWidth}px) {
    color: #001a32;
    margin-top: -22%;
    letter-spacing: 1.5px;
    font-size: 26px;
  }
`

const ContentSelectCategory = props => {
  let { device, selectedCountry } = useContext(AppContext)
  const countryNameSlug =
  selectedCountry && selectedCountry.name ? convertToSlug(selectedCountry.name) : "all"
  const countryID = selectedCountry && selectedCountry.id ? selectedCountry.id : "all"
  
  return (
    <ContentCategoryWrapper
      ismobile={device === "mobile"}
      isSmallDesktop={device === "smalldesktop"}
    >
      <CountryDetails>
        <MainHeading>WHAT WOULD YOU LIKE TO EXPLORE?</MainHeading>
        <Options>
          <ChildDiv>
            <Link to={stationsPageURL(countryID, countryNameSlug)}>
              <Image src={StaticRadioImage} />
              <Text>Listen to Radio</Text>
            </Link>
          </ChildDiv>
          <ChildDiv>
            <Link to={artistsPageURL(countryID, countryNameSlug)}>
              <Image src={StaticArtistImage} />
              <Text>Find an Artist</Text>
            </Link>
          </ChildDiv>
          <ChildDivMobile>
            <Link to={stationsPageURL(countryID, countryNameSlug)}>
              <Image src={StaticRadioMobileImage} />
              <Text>Listen to Radio</Text>
            </Link>
          </ChildDivMobile>
          <ChildDivMobile>
            <Link to={artistsPageURL(countryID, countryNameSlug)}>
              <Image src={StaticArtistMobileImage} />
              <Text>Find an Artist</Text>
            </Link>
          </ChildDivMobile>
        </Options>
      </CountryDetails>
    </ContentCategoryWrapper>
  )
}

export default ContentSelectCategory
